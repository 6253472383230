import React from 'react'
import Layout from '../components/layout'
import H2 from '../theming/H2'
import { useAgency } from '../hooks/use-agency'
import { AgencyAddress } from '../components/agency-address'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { CollapsiblePanel } from '../components/collapsible-panel'

export default (props) => {
  const { agency, settings } = useAgency()

  return (
    <Layout {...props}>
      <div className="c-row c-row--alpha">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
              <H2 className="section-title--big">
                <FormattedMessage id="page.privacy-title" />
              </H2>
            </div>
          </div>
        </div>
      </div>
      <div className="c-row c-row--alpha">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
              <article className="c-rich-text">
                <p>
                  <FormattedHTMLMessage
                    id="page.privacy-body"
                    values={{
                      name: agency.name,
                      website: agency.domain,
                    }}
                  />
                </p>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-1-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-1-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-2-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-2-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-3-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-3-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-4-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-4-body" />
                  </p>
                  <CookiesTable />
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-5-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-5-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-6-header" />
                  }
                >
                  <p>
                    <FormattedMessage id="page.privacy-section-6-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="page.privacy-section-7-header" />
                  }
                >
                  <p>
                    <FormattedHTMLMessage
                      id="page.privacy-section-7-body"
                      values={{ email: settings.email }}
                    />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={
                    <FormattedMessage id="terms.contact-information.title" />
                  }
                >
                  <p>
                    <FormattedMessage
                      id="terms.contact-information.body"
                      values={{
                        email: (
                          <a href={`mailto:${settings.email}`}>
                            {settings.email}
                          </a>
                        ),
                        address: <AgencyAddress />,
                      }}
                    />
                  </p>
                  <p className="text--right">
                    <AgencyAddress /> <br /> {settings.registration_number}
                  </p>
                </CollapsiblePanel>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function CookiesTable() {
  return (
    <table className="o-table-responsive">
      <thead>
        <tr>
          <th>
            <FormattedHTMLMessage id="page.privacy-cookies-table-header-1" />
          </th>
          <th>
            <FormattedHTMLMessage id="page.privacy-cookies-table-header-2" />
          </th>
          <th>
            <FormattedHTMLMessage id="page.privacy-cookies-table-header-3" />
          </th>
          <th>
            <FormattedHTMLMessage id="page.privacy-cookies-table-header-4" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r1-c1" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r1-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r1-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r1-c3" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r1-c4" />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r2-c1" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r2-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r2-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r2-c3" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r2-c4" />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r3-c1" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r3-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r3-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r3-c3" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r3-c4" />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r4-c1" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r4-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r4-c2" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r4-c3" />
          </td>
          <td>
            <FormattedHTMLMessage id="page.privacy-cookies-table-r4-c4" />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
