import React from 'react'
import classNames from 'classnames'

export function CollapsiblePanel({ title, children }) {
  const [isOpen, setOpen] = React.useState(false)
  return (
    <>
      <h2
        className={classNames('c-toggle', { 'is-toggled': isOpen })}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
        <i className="c-icon c-icon--toggle"></i>
      </h2>
      <div
        className={classNames({
          'is-hidden': !isOpen,
        })}
      >
        {children}
      </div>
    </>
  )
}
